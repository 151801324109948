.wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(50% 50% at 50% 50%, #2D3235 0%, #070B0E 100%);
    z-index: 10001;
    transform: translateZ(10px);
    opacity: 1;
    transition: opacity ease 0.5s;
}
.hidden{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000000;
    // display: none;
    video{
        width: 100%;
    }
}
.percent{
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    bottom: 2rem;
    right: 2.75rem;
    font-size: 4.50rem;
    line-height: 4.50rem;
    font-family: 'Open Sans Condensed';
    font-weight: 600;
    text-transform: uppercase;
}
.noise {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.04;
    background-repeat: repeat;
    background-size: 10rem;
    mix-blend-mode: normal;
    pointer-events: none;
    picture{
        display: flex;
        height: 100%;
        width: 100%;
    }
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.header {
    position: absolute;
    left: 0;
    top: 2.13rem;
    padding: 0 2.5rem;
    display: grid;
    grid-template-columns: 53.25rem 1fr 1fr;
    width: 100%;
    pointer-events: auto;
    color: var(--white);
}

.cell {
    display: flex;
    align-items: center;
}

.logo {
    display: flex;
    width: 3.75rem;
}

.info {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 11.88rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        width: 7.75rem;
        height: 7.75rem;
        border-radius: 50%;
        background: var(--electric-green);
        filter: blur(3.13rem);
        backface-visibility: hidden;
        z-index: -10;
    }
}

.icon {
    display: flex;
    width: 2.88rem;
    height: 2.88rem;
}

.title {
    margin-top: 1.56rem;
    font-family: 'Open Sans Condensed';
    font-weight: 600;
    font-size: 1.50rem;
    line-height: 1.38rem;
    text-transform: uppercase;
    text-align: center;
}

.text {
    margin-top: 0.81rem;
    width: 18.56rem;
    opacity: 0.5;
    text-align: center;
}

@media screen and (max-width: 767px) {
    .header {
        top: 0.56rem;
        padding: 0 0.63rem;
    }
    .logo {
        width: 1.88rem;
    }
    .info {
        bottom: 7.08rem;
        &::after {
            display: none;
        }
    }
    .icon {
        width: 2.88rem;
    }
    .title {
        margin-top: 0.63rem;
        font-size: 1.13rem;
        line-height: 1.5rem;
    }
    .text {
        margin-top: 0.56rem;
        width: 13.81rem;
    }
    .noise{
        display: none;
    }
    .percent{
        font-size: 2.25rem;
        line-height: 2.13rem;
        bottom: 1.56rem;
        right: 0.63rem;
    }
}