@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans.woff2') format('woff2'),
        url('OpenSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-SemiBold.woff2') format('woff2'),
        url('OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: 'Open Sans Condensed';
    src: url('OpenSansCondensed-SemiBold.woff2') format('woff2'),
        url('OpenSansCondensed-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

