:root {
    --white: #FFFFFF;
    --black: #000000;
    --middle-grey: #8C9FAA;
    --electric-green: #48CF00;
    --red: #CF0019;
}

html {
    overflow: hidden;
    overscroll-behavior: none;
    font-size: 1.111vw;
    width: 100%;
    height: 100%;
    background: var(--black);
    -webkit-font-smoothing: antialiased;
    scroll-snap-type: none;
}

body {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--black);
    color: var(--white);
    font-size: 1rem;
    line-height: 1.38rem;
    font-weight: 400;
    font-family: 'Open Sans';
    transition: opacity ease 0.5s;
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
    overscroll-behavior: none;
    scroll-snap-type: none;
    -webkit-overflow-scrolling: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    flex-shrink: 0;
}

#root {
    position: relative;
    width: 100%;
    z-index: 10;
}

.wrap {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    // background: var(--black);
    background: radial-gradient(50% 50% at 50% 50%, #2D3235 0%, #070B0E 100%);
}

.container {
    position: relative;
    width: 100%;
    padding: 0 15.44rem;
}

img, svg, picture {
    height: auto;
    width: 100%;
    flex-shrink: 0;
}
img, picture {
    transform: translateZ(0);
}

span, p, input, button, a, ul {
    font: inherit;
    color: inherit;
}

@media screen and (max-width: 767px) {
    html {
        font-size: 5vw;
        height: 100%;
    }
    body {
        height: 100%;
        font-size: 0.88rem;
        line-height: 1.38rem;
    }
    #root{
        height: 100%;
    }
    *{
        cursor: none;
    }
    .wrap{
        
    height: 100%;
    }
}